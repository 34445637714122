@use 'sass:map';
@use './external/light';
@use './external/dark';

@function -themed-elevations($theme) {
  $_low-up: (
    color: map.get($theme, wri-elevation-low-up-color),
    type: map.get($theme, wri-elevation-low-up-type),
    x: map.get($theme, wri-elevation-low-up-x),
    y: map.get($theme, wri-elevation-low-up-y),
    blur: map.get($theme, wri-elevation-low-up-blur),
    spread: map.get($theme, wri-elevation-low-up-spread)
  );
  $_low-down: (
    color: map.get($theme, wri-elevation-low-down-color),
    type: map.get($theme, wri-elevation-low-down-type),
    x: map.get($theme, wri-elevation-low-down-x),
    y: map.get($theme, wri-elevation-low-down-y),
    blur: map.get($theme, wri-elevation-low-down-blur),
    spread: map.get($theme, wri-elevation-low-down-spread)
  );
  $_high-up: (
    color: map.get($theme, wri-elevation-high-up-color),
    type: map.get($theme, wri-elevation-high-up-type),
    x: map.get($theme, wri-elevation-high-up-x),
    y: map.get($theme, wri-elevation-high-up-y),
    blur: map.get($theme, wri-elevation-high-up-blur),
    spread: map.get($theme, wri-elevation-high-up-spread)
  );
  $_high-down: (
    color: map.get($theme, wri-elevation-high-down-color),
    type: map.get($theme, wri-elevation-high-down-type),
    x: map.get($theme, wri-elevation-high-down-x),
    y: map.get($theme, wri-elevation-high-down-y),
    blur: map.get($theme, wri-elevation-high-down-blur),
    spread: map.get($theme, wri-elevation-high-down-spread)
  );
  $_elevations: (
    low-up: $_low-up,
    low-down: $_low-down,
    high-up: $_high-up,
    high-down: $_high-down
  );

  @return $_elevations;
}

@mixin -wri-elevation($theme, $name) {
  @if map-has-key(-themed-elevations($theme), $name) {
    box-shadow: #{map.get(map.get(-themed-elevations($theme), $name), x)}px
      #{map.get(map.get(-themed-elevations($theme), $name), y)}px
      #{map.get(map.get(-themed-elevations($theme), $name), blur)}px
      map.get(map.get(-themed-elevations($theme), $name), color);
  }
}

@mixin wri-elevation($name) {
  @media (prefers-color-scheme: light) {
    @include -wri-elevation(light.$light-theme, $name);
  }

  @media (prefers-color-scheme: dark) {
    @include -wri-elevation(dark.$dark-theme, $name);
  }
}
