@use 'sass:map';
@use './external/light';
@use './external/dark';

@mixin -spread-colors($theme: light.$light-theme) {
  @each $name, $color in $theme {
    --#{$name}: #{$color};
  }
}

:root {
  @include -spread-colors(light.$light-theme);

  @media (prefers-color-scheme: light) {
    @include -spread-colors(light.$light-theme);
  }

  @media (prefers-color-scheme: dark) {
    @include -spread-colors(dark.$dark-theme);
  }
}

@function wri-color($name) {
  @if map.has-key(light.$light-theme, $name) {
    @return map.get(light.$light-theme, $name);
  } @else {
    @return white;
  }
}
