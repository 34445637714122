@use 'utilities';
@use 'colors';
@use 'elevations';
@use 'typography';
@use 'helpers';
@use 'sass:map';
@use 'buttons';

div {
  overflow-wrap: anywhere;
}

.flex-align-left {
  margin-left: auto;
}

.menu-link,
.at {
  overflow-wrap: normal;
}

.align-center {
  text-align: center;
}

.focus-outline {
  outline: none;

  &:focus {
    outline: none !important;
  }

  &:focus-visible {
    outline: utilities.rem-from-pixel(1) solid colors.wri-color(wri-action-base) !important;
    outline-offset: utilities.rem-from-pixel(2) !important;
  }
}

.tabfocus:focus {
  .cross-sell-card {
    outline: utilities.rem-from-pixel(1) solid colors.wri-color(wri-action-base) !important;
  }
}

a:hover,
button:hover {
  cursor: pointer;
}

.tabfocus:hover {
  color: colors.wri-color(wri-action-base);
  text-decoration: none;

  .cross-sell-card {
    outline: utilities.rem-from-pixel(2) solid colors.wri-color(wri-action-base) !important;
  }

  .flavor-card {
    color: colors.wri-color(wri-ink-base);
  }
}

.toast-error {
  background-color: colors.wri-color(wri-error-light) !important;
  color: colors.wri-color(wri-error-base) !important;
  top: utilities.rem-from-pixel(32);
  width: 75vw !important;
  background-image: url('../assets/error-info.svg') !important;
  display: grid;
  grid-template-columns: 1fr utilities.rem-from-pixel(32);
  grid-template-areas: 'msg cls';
  align-items: center;
  text-overflow: ellipsis;
}

.toast-success {
  background-color: colors.wri-color(wri-positive-light) !important;
  color: colors.wri-color(wri-ink-light) !important;
  top: utilities.rem-from-pixel(32);
  width: 75vw !important;
  background-image: url('../assets/success-check.svg') !important;
  display: grid;
  grid-template-columns: 1fr utilities.rem-from-pixel(32);
  grid-template-areas: 'msg cls';
  align-items: center;
  text-overflow: ellipsis;
}

.toast-warning {
  background-color: colors.wri-color(wri-warning-light) !important;
  color: colors.wri-color(wri-ink-light) !important;
  top: utilities.rem-from-pixel(32);
  width: 75vw !important;
  background-image: url('../assets/warning.svg') !important;
  display: grid;
  grid-template-columns: 1fr utilities.rem-from-pixel(32);
  grid-template-areas: 'msg cls';
  align-items: center;
  text-overflow: ellipsis;
}

.toast-message {
  grid-area: msg;
}

.toast-close-button {
  margin-left: auto !important;
  background-repeat: no-repeat !important;
  background-image: url('../assets/close.svg') !important;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  width: utilities.rem-from-pixel(32);
  top: utilities.rem-from-pixel(8) !important;
  grid-area: cls;
}

.toast-container .ngx-toastr,
toast-container .ngx-toastr:hover {
  @include elevations.wri-elevation(low-up);
  box-shadow: none !important;
}

.modal-backdrop {
  background-color: colors.wri-color(wri-ink-base) !important;
}

.wri-btn {
  @include typography.wri-typography(button-1);
  border-radius: utilities.rem-from-pixel(4);
  border: none;
  text-wrap: nowrap;
  padding: utilities.rem-from-pixel(10) utilities.rem-from-pixel(24)
    utilities.rem-from-pixel(8) utilities.rem-from-pixel(24);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &-primary {
    @include buttons.wri-button('primary');
  }

  &-secondary {
    @include buttons.wri-button('secondary');
  }

  &-tertiary {
    @include buttons.wri-button('tertiary');
  }

  &-text {
    @include buttons.wri-button('text');
  }

  &-secondary-error {
    @include buttons.wri-button('secondary-error');
  }

  &-primary-icon {
    @include buttons.wri-button('primary-icon');
  }

  &-secondary-icon {
    @include buttons.wri-button('secondary-icon');
  }

  &-tertiary-icon {
    @include buttons.wri-button('tertiary-icon');
  }

  &-text-icon {
    @include buttons.wri-button('text-icon');
  }
}

.sticky-footer {
  .wri-btn-primary-icon:hover {
    background-color: colors.wri-color(wri-action-base);
    cursor: pointer;
  }
}

.wri-modal {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: utilities.rem-from-pixel(16);
  }

  &__title {
    @include typography.wri-typography(title-2);
    text-transform: uppercase;
    text-align: center;
  }

  &__description {
    @include typography.wri-typography(body-3);
    text-align: center;
    color: colors.wri-color(wri-ink-base);
  }

  &__btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: utilities.rem-from-pixel(16);
  }
}

.common-modal {
  .modal {
    &-sm {
      max-width: utilities.rem-from-pixel(375);
    }

    &-md {
      max-width: utilities.rem-from-pixel(700);
    }

    &-lg {
      max-width: utilities.rem-from-pixel(1190);
    }
  }

  .modal-content {
    padding: utilities.rem-from-pixel(40) utilities.rem-from-pixel(24);
    border-radius: 0;
  }

  .info-icon {
    background: none;
    border: none;
    color: colors.wri-color(wri-action-base);
    display: inline-flex;
    padding: 0;
    font-size: utilities.rem-from-pixel(16);
    text-align: left;

    &:hover {
      color: colors.wri-color(wri-action-dark);
      cursor: pointer;
    }
  }
}

.round-up-modal .modal-content {
  padding: utilities.rem-from-pixel(40) utilities.rem-from-pixel(24);
}

body.modal-open .modal {
  background: rgba(7, 7, 7, 50%);
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.wri-divider {
  border: 0;
  border-top: utilities.rem-from-pixel(1) solid colors.wri-color(wri-bg-base);
  margin: utilities.rem-from-pixel(16) 0;
}

.arrow-right-icon {
  transform: rotate(180deg);
}

.flex {
  display: flex;
}

.column-flex {
  flex-direction: column;
}

.menu-item-background {
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    0deg,
    colors.wri-color(wri-bg-light) 3.25%,
    transparent 97.31%
  );

  &::before {
    content: '';
    position: absolute;
    width: 200vw;
    height: 200vh;
    top: -50vh;
    left: -50vw;
    z-index: -1;
    background-image: url('../assets/menu-bg-banner.svg');
    background-repeat: repeat;
    transform: rotate(-15deg);
  }

  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -2;
    background-color: colors.wri-color(wri-bg-light);
  }
}

.p-left-15 {
  padding-left: utilities.rem-from-pixel(15);
}

.m-left-15 {
  margin-left: utilities.rem-from-pixel(15);
}

// to override slick carousel css
.slick-prev.slick-arrow {
  left: utilities.rem-from-pixel(0) !important;
  border: none;
  background: rgba(255, 255, 255, 60%) 50% 50% no-repeat
    url('/assets/icons/black-arrow-left-small.svg');

  @media only screen and (max-width: 991px) {
    left: utilities.rem-from-pixel(12) !important;
  }

  &:hover {
    border: none;
    background: rgba(11, 88, 43, 80%) 50% 50% no-repeat
      url('/assets/icons/white-arrow-left-small.svg');
  }
}

.slick-next.slick-arrow {
  right: utilities.rem-from-pixel(0) !important;
  border: none;
  background: rgba(255, 255, 255, 60%) 50% 50% no-repeat
    url('/assets/icons/black-arrow-right-small.svg');

  @media only screen and (max-width: 991px) {
    right: utilities.rem-from-pixel(12) !important;
  }

  &:hover {
    border: none;
    background: rgba(11, 88, 43, 80%) 50% 50% no-repeat
      url('/assets/icons/white-arrow-right-small.svg');
  }
}

.slick-disabled {
  display: none !important;
  pointer-events: none;
}

.slick-track {
  display: flex !important;
}

.slick-dots li {
  button:focus {
    outline: none !important;
  }

  button::before {
    top: utilities.rem-from-pixel(-5);
    color: transparent;
  }

  &.slick-active {
    background-color: colors.wri-color(wri-action-base);
    width: utilities.rem-from-pixel(33);

    button::before {
      color: transparent;
    }
  }

  background-color: colors.wri-color(wri-bg-base);
  width: utilities.rem-from-pixel(18);
  height: utilities.rem-from-pixel(8);
  border-radius: utilities.rem-from-pixel(5);
}

wri-modifier-modal,
wri-choose-flavor-modal {
  .toast-container.inline {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    padding: 0 utilities.rem-from-pixel(12);
    height: auto;
  }

  .toast-container.inline .ngx-toastr {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 15%) !important;
    border-radius: utilities.rem-from-pixel(4);
    top: 0;
  }

  .inline-toast {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
    height: auto;
  }
}

.remove-payment-method-modal .modal-dialog,
.remove-favorite-location-modal .modal-dialog,
.remove-my-address-modal .modal-dialog {
  max-width: utilities.rem-from-pixel(310);
}

.active-offer-modal .modal-dialog {
  max-width: utilities.rem-from-pixel(488);
}

.full-width {
  width: 100%;
}
