// Open Sans
@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700';

// wri-icons
@font-face {
  font-family: wri-icons;
  font-style: normal;
  font-display: block;
  text-rendering: optimizelegibility;
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/rpbvrmhhbmbrqhmwt58rw4t/wri-icons-2051.otf')
      format('opentype'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/qxrt8b6gv3gc5nnwjsgmv5t/wri-icons-2051.woff')
      format('woff'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/4n89j7fk58nfc8bqw5snfh/wri-icons-2051.ttf')
      format('truetype'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/7tstb73kfqk3xr4t4b4f27fx/wri-icons-2051.svg')
      format('svg');
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/r3b5k7qt9gc6w49jcrqvvx/RocGroteskCond-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/rmj7gvg8wkbrg8s87z2rszjc/RocGroteskCond-Bold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/rhfv6znz9ntcxs9vv3963j/RocGroteskCond-ExtraBold.otf');
  font-weight: 700;
}
