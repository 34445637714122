/* stylelint scss/dollar-variable-pattern: /foo-.+/ */
@use 'sass:map';
@use 'sass:list';
@use 'sass:string';
@use 'fonts';
@use 'utilities';
@use './external/typography';

@function -wri-font-family($family) {
  @return string.quote(#{$family}), 'Open Sans', sans-serif;
}

@function -wri-font-weight-from-string($weight) {
  @if type-of($weight) == string {
    @if string.quote($weight) == 'Bold' {
      @return 700;
    }

    @if string.quote($weight) == 'Extra Bold Oblique' {
      @return 750;
    }
  }

  @return $weight;
}

@function -wri-font-weight($weight) {
  @if type-of($weight) == number {
    @return $weight;
  }

  @if type-of($weight) == list {
    @return -wri-font-weight-from-string(utilities.to-string($weight));
  }

  @if type-of($weight) == string {
    @return -wri-font-weight-from-string($weight);
  }

  @return $weight;
}

$-title1: (
  font-family: -wri-font-family(typography.$wri-title-title1-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-title-title1-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-title-title1-line-height),
  font-weight: -wri-font-weight(typography.$wri-title-title1-font-weight),
  text-transform: typography.$wri-title-title1-text-case,
  text-decoration: typography.$wri-title-title1-text-decoration
);
$-title2: (
  font-family: -wri-font-family(typography.$wri-title-title2-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-title-title2-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-title-title2-line-height),
  font-weight: -wri-font-weight(typography.$wri-title-title2-font-weight),
  text-transform: typography.$wri-title-title2-text-case,
  text-decoration: typography.$wri-title-title2-text-decoration
);
$-title3: (
  font-family: -wri-font-family(typography.$wri-title-title3-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-title-title3-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-title-title3-line-height),
  font-weight: -wri-font-weight(typography.$wri-title-title3-font-weight),
  text-transform: typography.$wri-title-title3-text-case,
  text-decoration: typography.$wri-title-title3-text-decoration
);
$-title4: (
  font-family: -wri-font-family(typography.$wri-title-title4-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-title-title4-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-title-title4-line-height),
  font-weight: -wri-font-weight(typography.$wri-title-title4-font-weight),
  text-transform: typography.$wri-title-title4-text-case,
  text-decoration: typography.$wri-title-title4-text-decoration,
  letter-spacing:
    utilities.rem-from-pixel(typography.$wri-title-title4-letter-spacing)
);
$-title5: (
  font-family: -wri-font-family(typography.$wri-title-title5-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-title-title5-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-title-title5-line-height),
  font-weight: -wri-font-weight(typography.$wri-title-title5-font-weight),
  text-transform: typography.$wri-title-title5-text-case,
  text-decoration: typography.$wri-title-title5-text-decoration,
  letter-spacing:
    utilities.rem-from-pixel(typography.$wri-title-title5-letter-spacing)
);
$-subtitle1: (
  font-family: -wri-font-family(typography.$wri-subtitle-subtitle1-font-family),
  font-size:
    utilities.rem-from-pixel(typography.$wri-subtitle-subtitle1-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-subtitle-subtitle1-line-height),
  font-weight: -wri-font-weight(typography.$wri-subtitle-subtitle1-font-weight),
  text-transform: typography.$wri-subtitle-subtitle1-text-case,
  text-decoration: typography.$wri-subtitle-subtitle1-text-decoration
);
$-subtitle2: (
  font-family: -wri-font-family(typography.$wri-subtitle-subtitle2-font-family),
  font-size:
    utilities.rem-from-pixel(typography.$wri-subtitle-subtitle2-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-subtitle-subtitle2-line-height),
  font-weight: -wri-font-weight(typography.$wri-subtitle-subtitle2-font-weight),
  text-transform: typography.$wri-subtitle-subtitle2-text-case,
  text-decoration: typography.$wri-subtitle-subtitle2-text-decoration
);
$-subtitle3: (
  font-family: -wri-font-family(typography.$wri-subtitle-subtitle3-font-family),
  font-size:
    utilities.rem-from-pixel(typography.$wri-subtitle-subtitle3-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-subtitle-subtitle3-line-height),
  font-weight: -wri-font-weight(typography.$wri-subtitle-subtitle3-font-weight),
  text-transform: typography.$wri-subtitle-subtitle3-text-case,
  text-decoration: typography.$wri-subtitle-subtitle3-text-decoration
);
$-subtitle4: (
  font-family: -wri-font-family(typography.$wri-subtitle-subtitle4-font-family),
  font-size:
    utilities.rem-from-pixel(typography.$wri-subtitle-subtitle4-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-subtitle-subtitle4-line-height),
  font-weight: -wri-font-weight(typography.$wri-subtitle-subtitle4-font-weight),
  text-transform: typography.$wri-subtitle-subtitle4-text-case,
  text-decoration: typography.$wri-subtitle-subtitle4-text-decoration
);
$-body1: (
  font-family: -wri-font-family(typography.$wri-body-body1-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-body-body1-font-size),
  line-height: utilities.rem-from-pixel(typography.$wri-body-body1-line-height),
  font-weight: -wri-font-weight(typography.$wri-body-body1-font-weight),
  text-transform: typography.$wri-body-body1-text-case,
  text-decoration: typography.$wri-body-body1-text-decoration
);
$-body2: (
  font-family: -wri-font-family(typography.$wri-body-body2-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-body-body2-font-size),
  line-height: utilities.rem-from-pixel(typography.$wri-body-body2-line-height),
  font-weight: -wri-font-weight(typography.$wri-body-body2-font-weight),
  text-transform: typography.$wri-body-body2-text-case,
  text-decoration: typography.$wri-body-body2-text-decoration
);
$-body3: (
  font-family: -wri-font-family(typography.$wri-body-body3-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-body-body3-font-size),
  line-height: utilities.rem-from-pixel(typography.$wri-body-body3-line-height),
  font-weight: -wri-font-weight(typography.$wri-body-body3-font-weight),
  text-transform: typography.$wri-body-body3-text-case,
  text-decoration: typography.$wri-body-body3-text-decoration
);
$-body4: (
  font-family: -wri-font-family(typography.$wri-body-body4-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-body-body4-font-size),
  line-height: utilities.rem-from-pixel(typography.$wri-body-body4-line-height),
  font-weight: -wri-font-weight(typography.$wri-body-body4-font-weight),
  text-transform: typography.$wri-body-body4-text-case,
  text-decoration: typography.$wri-body-body4-text-decoration
);
$-body-heavy1: (
  font-family: -wri-font-family(typography.$wri-body-heavy-body1-font-family),
  font-size:
    utilities.rem-from-pixel(typography.$wri-body-heavy-body1-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-body-heavy-body1-line-height),
  font-weight: -wri-font-weight(typography.$wri-body-heavy-body1-font-weight),
  text-transform: typography.$wri-body-heavy-body1-text-case,
  text-decoration: typography.$wri-body-heavy-body1-text-decoration
);
$-body-heavy2: (
  font-family: -wri-font-family(typography.$wri-body-heavy-body2-font-family),
  font-size:
    utilities.rem-from-pixel(typography.$wri-body-heavy-body2-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-body-heavy-body2-line-height),
  font-weight: -wri-font-weight(typography.$wri-body-heavy-body2-font-weight),
  text-transform: typography.$wri-body-heavy-body2-text-case,
  text-decoration: typography.$wri-body-heavy-body2-text-decoration
);
$-body-heavy3: (
  font-family: -wri-font-family(typography.$wri-body-heavy-body3-font-family),
  font-size:
    utilities.rem-from-pixel(typography.$wri-body-heavy-body3-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-body-heavy-body3-line-height),
  font-weight: -wri-font-weight(typography.$wri-body-heavy-body3-font-weight),
  text-transform: typography.$wri-body-heavy-body3-text-case,
  text-decoration: typography.$wri-body-heavy-body3-text-decoration
);
$-body-heavy4: (
  font-family: -wri-font-family(typography.$wri-body-heavy-body4-font-family),
  font-size:
    utilities.rem-from-pixel(typography.$wri-body-heavy-body4-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-body-heavy-body4-line-height),
  font-weight: -wri-font-weight(typography.$wri-body-heavy-body4-font-weight),
  text-transform: typography.$wri-body-heavy-body4-text-case,
  text-decoration: typography.$wri-body-heavy-body4-text-decoration
);
$-button1: (
  font-family: -wri-font-family(typography.$wri-button-button1-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-button-button1-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-button-button1-line-height),
  font-weight: -wri-font-weight(typography.$wri-button-button1-font-weight),
  text-transform: typography.$wri-button-button1-text-case,
  text-decoration: typography.$wri-button-button1-text-decoration,
  letter-spacing:
    utilities.rem-from-pixel(typography.$wri-button-button1-letter-spacing)
);
$-button2: (
  font-family: -wri-font-family(typography.$wri-button-button2-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-button-button2-font-size),
  line-height:
    utilities.rem-from-pixel(typography.$wri-button-button2-line-height),
  font-weight: -wri-font-weight(typography.$wri-button-button2-font-weight),
  text-transform: typography.$wri-button-button2-text-case,
  text-decoration: typography.$wri-button-button2-text-decoration,
  letter-spacing:
    utilities.rem-from-pixel(typography.$wri-button-button2-letter-spacing)
);
$-label: (
  font-family: -wri-font-family(typography.$wri-label-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-label-font-size),
  line-height: utilities.rem-from-pixel(typography.$wri-label-line-height),
  font-weight: -wri-font-weight(typography.$wri-label-font-weight),
  text-transform: typography.$wri-label-text-case,
  text-decoration: typography.$wri-label-text-decoration
);
$-metadata: (
  font-family: -wri-font-family(typography.$wri-metadata-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-metadata-font-size),
  line-height: utilities.rem-from-pixel(typography.$wri-metadata-line-height),
  font-weight: -wri-font-weight(typography.$wri-metadata-font-weight),
  text-transform: typography.$wri-metadata-text-case,
  text-decoration: typography.$wri-metadata-text-decoration
);
$-hyperlink: (
  font-family: -wri-font-family(typography.$wri-hyperlink-hyperlink-3-font-family),
  font-size: utilities.rem-from-pixel(typography.$wri-hyperlink-hyperlink-3-font-size),
  line-height: utilities.rem-from-pixel(typography.$wri-hyperlink-hyperlink-3-line-height),
  font-weight: -wri-font-weight(typography.$wri-hyperlink-hyperlink-3-font-weight),
  text-transform: typography.$wri-hyperlink-hyperlink-3-text-case,
  text-decoration: typography.$wri-hyperlink-hyperlink-3-text-decoration
);
$-typographies: (
  title-1: $-title1,
  title-2: $-title2,
  title-3: $-title3,
  title-4: $-title4,
  title-5: $-title5,
  subtitle-1: $-subtitle1,
  subtitle-2: $-subtitle2,
  subtitle-3: $-subtitle3,
  subtitle-4: $-subtitle4,
  body-1: $-body1,
  body-2: $-body2,
  body-3: $-body3,
  body-4: $-body4,
  body-heavy-1: $-body-heavy1,
  body-heavy-2: $-body-heavy2,
  body-heavy-3: $-body-heavy3,
  body-heavy-4: $-body-heavy4,
  button-1: $-button1,
  button-2: $-button2,
  label: $-label,
  metadata: $-metadata,
  hyperlink: $-hyperlink
);

@mixin wri-typography($name) {
  @if map.has-key($-typographies, $name) {
    @each $prop, $val in map.get($-typographies, $name) {
      #{$prop}: #{$val};
    }
  }
}
