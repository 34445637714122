@import 'media-queries';

$text-hr-spacer: ws-get-spacing('medium');

@mixin text-hr {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: ws-get-spacing('medium');
  margin-bottom: ws-get-spacing('medium');

  &::before,
  &::after {
    content: '';
    height: 1px;
    background-color: ws-get-color('black', 5);
    flex: 1;
  }

  &::before {
    margin-right: $text-hr-spacer;
  }

  &::after {
    margin-left: $text-hr-spacer;
  }
}

// Container
@mixin content-container($size: ws-get-width('xlarge')) {
  @include make-container;
  max-width: $size;
}

// Range
@mixin range-variant($parent, $bg, $active-bg) {
  #{$parent} {
    &::-webkit-slider-thumb {
      @include gradient-bg($bg);

      &:active {
        @include gradient-bg($active-bg);
      }
    }

    &::-moz-range-thumb {
      @include gradient-bg($bg);

      &:active {
        @include gradient-bg($active-bg);
      }
    }

    &::-ms-thumb {
      @include gradient-bg($bg);

      &:active {
        @include gradient-bg($active-bg);
      }
    }
  }
}

@mixin range-variant-complex($parent, $bg, $active-bg) {
  #{$parent} {
    .noUi-handle {
      @include gradient-bg($bg);

      &:active {
        @include gradient-bg($active-bg);
      }
    }
  }
}

@mixin icon-variant($parent, $color) {
  #{$parent} {
    background-color: $color;
    border-color: $color;
  }
}

@mixin visibility-icon {
  color: #4d4d4d;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translate(0, -50%);
  border: none;
  padding: 0;
  background: transparent;
}
