@use 'sass:color';
@use 'utilities' as u;
@use 'colors' as c;
@use 'typography' as t;

@mixin wri-button($type: 'primary') {
  &:focus-visible {
    outline: u.rem-from-pixel(1) solid c.wri-color(wri-action-base);
    outline-offset: u.rem-from-pixel(2);
  }

  @if $type == 'primary' {
    background: c.wri-color(wri-action-base);
    color: c.wri-color(wri-bg-lightest);
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      color: c.wri-color(wri-bg-lightest);
      background-color: c.wri-color(wri-action-dark);
    }

    &:disabled,
    &[disabled=''] {
      background: c.wri-color(wri-ink-disabled);
      pointer-events: none;
    }
  }

  @if $type == 'secondary' {
    background: c.wri-color(wri-bg-lightest);
    color: c.wri-color(wri-action-base);
    border: u.rem-from-pixel(1) solid c.wri-color(wri-action-base);
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-action-dark);
      color: c.wri-color(wri-action-dark);
      background-color: #0043241a;
    }

    &:disabled,
    &[disabled=''] {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-disabled);
      background: c.wri-color(wri-bg-lightest);
      color: c.wri-color(wri-ink-disabled);
      pointer-events: none;
    }
  }

  @if $type == 'tertiary' {
    background: c.wri-color(wri-bg-lightest);
    color: c.wri-color(wri-ink-light);
    border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-light);
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-base);
      color: c.wri-color(wri-ink-base);
      background-color: #0707071a;
    }

    &:disabled,
    &[disabled=''] {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-disabled);
      background: c.wri-color(wri-bg-lightest);
      color: c.wri-color(wri-ink-disabled);
      pointer-events: none;
    }
  }

  @if $type == 'text' {
    @include t.wri-typography(title-4);
    color: c.wri-color(wri-action-base);
    background-color: transparent;
    width: 100%;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      color: c.wri-color(wri-action-dark);
      text-decoration: none;
    }

    &:disabled,
    &[disabled=''] {
      color: c.wri-color(wri-ink-disabled);
    }
  }

  @if $type == 'secondary-error' {
    background: c.wri-color(wri-bg-lightest);
    color: c.wri-color(wri-error-base);
    border: u.rem-from-pixel(1) solid c.wri-color(wri-error-base);
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      background-color: #bb00001a;
    }

    &:disabled,
    &[disabled=''] {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-disabled);
      background: c.wri-color(wri-bg-lightest);
      color: c.wri-color(wri-ink-disabled);
      pointer-events: none;
    }
  }

  @if $type == 'primary-icon' {
    justify-content: center;
    align-items: center;
    display: flex;
    background: c.wri-color(wri-action-base);
    color: c.wri-color(wri-bg-lightest);

    &:hover,
    &:active,
    &:focus {
      color: c.wri-color(wri-bg-lightest);
      background-color: c.wri-color(wri-action-dark);
    }

    &:disabled,
    &[disabled=''] {
      background: c.wri-color(wri-ink-disabled);
      pointer-events: none;
    }
  }

  @if $type == 'secondary-icon' {
    justify-content: center;
    align-items: center;
    display: flex;
    background: c.wri-color(wri-bg-lightest);
    color: c.wri-color(wri-action-base);
    border: u.rem-from-pixel(1) solid c.wri-color(wri-action-base);

    &:hover,
    &:active,
    &:focus {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-action-dark);
      color: c.wri-color(wri-action-dark);
      background-color: #0043241a;
    }

    &:disabled,
    &[disabled=''] {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-disabled);
      background: c.wri-color(wri-bg-lightest);
      color: c.wri-color(wri-ink-disabled);
      pointer-events: none;
    }
  }

  @if $type == 'tertiary-icon' {
    justify-content: center;
    align-items: center;
    display: flex;
    background: c.wri-color(wri-bg-lightest);
    color: c.wri-color(wri-ink-light);
    border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-light);

    &:hover,
    &:active,
    &:focus {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-base);
      color: c.wri-color(wri-ink-base);
      background-color: #0707071a;
    }

    &:disabled,
    &[disabled=''] {
      border: u.rem-from-pixel(1) solid c.wri-color(wri-ink-disabled);
      background: c.wri-color(wri-bg-lightest);
      color: c.wri-color(wri-ink-disabled);
      pointer-events: none;
    }
  }

  @if $type == 'text-icon' {
    @include t.wri-typography(title-4);
    justify-content: center;
    align-items: center;
    display: flex;
    color: c.wri-color(wri-action-base);
    background-color: transparent;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      color: c.wri-color(wri-action-dark);
      text-decoration: none;
    }

    &:disabled,
    &[disabled=''] {
      color: c.wri-color(wri-ink-disabled);
    }
  }
}
