/*!*****************************************************

Freak Flags, Copyright ©2022 Michael P. Cohen. Freak flags is licenced under the MIT licence.

For complete information visit: www.freakflagsprite.com

******************************************************/

.fflag {
  background-image: url('../assets/images/flagSprite60.png');
  background-repeat: no-repeat;
  background-size: 100% 49494%;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  box-sizing: content-box;
  box-shadow: 0 0 0 1px rgb(220, 220, 220);
}
.fflag-CA {
  background-position: center 0.2287%;
}
.fflag-MX {
  background-position: center 0.4524%;
}
.fflag-US {
  background-position: center 0.6721%;
}
.fflag-ID {
  background-position: center 0.8958%;
}
.fflag-SG {
  background-position: left 1.1162%;
}
.fflag-FR {
  background-position: center 1.3379%;
}
.fflag-ES {
  background-position: left 1.5589%;
}
.fflag-GB {
  background-position: center 1.7805%;
}
.fflag-AE {
  background-position: center 2.0047%;
}

.fflag.ff-sm {
  width: 18px;
  height: 11px;
}
.fflag.ff-md {
  width: 27px;
  height: 17px;
}
.fflag.ff-lg {
  width: 42px;
  height: 27px;
}
.fflag.ff-xl {
  width: 60px;
  height: 37px;
}
